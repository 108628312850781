export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#07c',
    secondary: '#30c',
    muted: '#f6f6f6'
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: [1, 2, 3]
    },
    a: {
      color: 'inherit'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      backgroundColor: 'muted',
      p: 3,
      borderRadius: 4,
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  }
}
